<template>
<Navbar :user="user" />
  <section class="instructor-details-area pt-100 pb-70" v-if="!isLoading">
      <div class="container">
        <div class="row">
            <div class="col-md-2" id="sticky-sidebar">
                <div class="sticky-top">
                    <Sidebar :user="user" />
                </div>
            </div>
            <div class="col" id="main">
                <div class="instructor-details-desc">
                    <div class="row justify-content-center">
                    <div class="col-lg-10 col-md-10">
                        
                        <div class="text-center">
                            <div class="alert alert-success" v-if="success">
                                <p class="text-center">{{ success }}</p>
                            </div>
                            <div class="alert alert-danger" v-if="imageErrors">
                                <p class="text-center">{{ imageErrors }}</p>
                            </div>
                            <div class="alert alert-danger" v-else-if="errors[0]">
                                <p class="text-center">{{ errors[0] }}</p>
                            </div>
                            <div class="alert alert-danger" v-else-if="errors">
                                <p class="text-center">{{ errors }}</p>
                            </div>
                            <div class="instructor-details-sidebar">
                                <div v-if="form.photo">
                                    <img :src="form.photo" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                </div>
                                <div v-else-if="user.avatar">
                                    <img v-bind:src="'https://apitraining.vipawaworks.com/' + user.avatar" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/team/1.jpg" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                </div>
                                <button v-on:click="editable = !editable" class=" btn text-center text-default mt-2 border-0 bg-light collapsed" type="button">
                                    <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                                </button>
                                <form @submit.prevent="profileUpload" v-if="editable" id="hide" enctype="multipart/form-data" method="post">
                                    <div class="custom-file">
                                        <input class="custom-file-input input-sm" @change="imageSelected" type="file"  id="customFile">
                                    </div>
                                    <button class="btn btn-outline-success" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                    <button type="submit" class="btn btn-outline-success mt-2 ms-2" v-else>Upload</button>
                                </form>
                            </div>
                        </div>
                        <div class="instructor-details">
                        <h3>{{ user.fname }} {{ user.sname }} {{ user.lname }} </h3>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div>
                                    <h5 class="text-center">Personal Information</h5>
                                    <div class="d-flex flex-row-reverse">
                                        <router-link :to="{ name: 'TrainerEditProfile'}" class="btn text-default end-0">
                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                                        </router-link>
                                    </div>
                                    <div>
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td><span class="fw-bold">First Name:</span> {{ user.fname }}</td>
                                                <td><span class="fw-bold">Second Name:</span> {{ user.sname }}</td>
                                                <td><span class="fw-bold">Last Name:</span> {{ user.lname }}</td>
                                                <td><span class="fw-bold">Email:</span> {{ user.email }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <hr>
                                <div class="">
                                    <div class="d-flex flex-row-reverse">
                                        <router-link :to="{ name: 'TrainerPasswordProfile'}" class="btn text-default end-0">
                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                                        </router-link>
                                    </div>
                                    <h5 class="text-center">Password</h5>
                                    <h6 class="text-center">Change your account password</h6>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </section>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/trainer/Sidebar.vue'
import axios from 'axios'
import Loading from '@/components/Loading.vue'
export default {
 components: { Navbar,Loading, Sidebar },
    data(){
        return {
            drawer: true,
            currentUser: {},
            user: {},
            jobs: {},
            editable: false,
            token: localStorage.getItem('user_data'),
            subscriber: {},
            isLoading: true,
            imageErrors: false,
            coursePhoto:null,
            form:{
                photo: null
            },
            submitting: false,
            success: false,
            errors: false,
        }
    },
    methods: {
        getUser(){
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                    localStorage.removeItem('user_data')
                    this.$router.push({ name: 'Login'})
                }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        imageSelected(event){
            let file = event.target.files[0]
            if (file.size > 1548770) {
                this.imageErrors = 'Image Should be less than 1MB'
            }else{
                this.errors = false
                let reader = new FileReader()
                reader.onload = event => {
                    this.form.photo = event.target.result
                }
                reader.readAsDataURL(file)
            }
        },
        profileUpload(){
            this.submitting = true
            this.value = 'Uploading...'
            axios.post('https://apitraining.vipawaworks.com/api/auth/user/change_profile_picture', this.form).then(response => {
                this.$refs.clearForm.reset(),
                this.coursePhoto = response.data
                this.success = 'Cover Photo Changed'
                this.editable = !this.editable
                this.submitting = false
            }).catch((error) => {
                if (!error.response) {
                    this.editable = !this.editable
                    return this.success = 'Profile Picture Changed'
                }
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        onChange(e) {
            this.file = e.target.files[0];
        },
    },
    created(){
        document.title = `Dashboard - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser()  
    }

}
</script>

<style>
.avatar.avatar-medium {
	height: 110px;
	width: 110px;
}
</style>